import { TypeManagerDecorator } from "../../../main/type.map.service";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from "@angular/core";
import { S25ItemI } from "../../../pojo/S25ItemI";
import { MultiselectModelI } from "../../s25-multiselect/s25.multiselect.component";
import { Season } from "../seasons/seasons.service";
import { MatchStudentHousing, StudentHousingService } from "../student.housing.service";
import { S25Util } from "../../../util/s25-util";

@TypeManagerDecorator("s25-ng-student-housing-buildings")
@Component({
    selector: "s25-ng-student-housing-buildings",
    template: `
        <s25-ng-season-dropdown
            class="ngBlock c-margin-bottom--single"
            [selectedSeason]="buildingSeason"
            (selectedSeasonChange)="onBuildingSeasonChange($event)"
        ></s25-ng-season-dropdown>
        @if (buildingSelector) {
            <s25-ng-multiselect-search-criteria
                [type]="'buildings'"
                [modelBean]="buildingModel"
                [selectedItems]="studentBuildings"
                [popoverPlacement]="'right'"
                [useSecurity]="true"
            ></s25-ng-multiselect-search-criteria>

            <h3 class="c-margin-top--single">Building Info</h3>
            <div class="sortContainer c-margin-top--half">
                <ul s25-ng-dnd-sortable [items]="buildingModel.selectedItems">
                    @for (bldg of buildingModel.selectedItems; track bldg; let i = $index) {
                        <li s25-ng-dnd-sortable-item [index]="i">
                            <s25-ng-drag-handle></s25-ng-drag-handle>
                            <div class="sortableItemContainer">
                                <span>{{ bldg.itemName }}</span>
                                <div class="c-margin-top--half sortableItemData">
                                    <label
                                        >Match Groups (separate by ;)
                                        <input
                                            type="text"
                                            class="c-input"
                                            [(ngModel)]="buildingMatchGroups[bldg.itemId]"
                                    /></label>
                                </div>
                                <div class="c-margin-top--half sortableItemData">
                                    <label
                                        >Message
                                        <input type="text" class="c-input" [(ngModel)]="buildingMessages[bldg.itemId]"
                                    /></label>
                                </div>
                            </div>
                        </li>
                    }
                </ul>
            </div>
            <s25-ng-button [buttonClass]="'c-margin-top--single'" [type]="'primary'" [onClick]="setBuildings"
                >Save Buildings</s25-ng-button
            >
        }
    `,
    styles: `
        .sortContainer ul {
            padding-inline-start: 0;
        }

        .sortContainer ul li {
            list-style-type: none;
            padding: 0.5em 0;
            border-bottom: 1px solid #ddd;
            display: flex;
        }

        .sortableItemContainer {
            display: flow-root;
        }

        .sortableItemData {
            display: flex;
            flex-direction: row;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentHousingBuildingsComponent {
    constructor(private cd: ChangeDetectorRef) {}

    studentBuildings: S25ItemI[];
    buildingModel: MultiselectModelI = { showResult: true };
    buildingSelector = false;
    buildingSeason: Season;
    buildingMatchGroups: { [key: number]: string } = {};
    buildingMessages: { [key: number]: string } = {};

    onBuildingSeasonChange = (season: Season) => {
        this.buildingSelector = false;
        this.cd.detectChanges();
        this.buildingSeason = season;
        StudentHousingService.getStudentBuildings(season.seasonId).then((studentBuildings) => {
            studentBuildings.forEach((bldg) => {
                this.buildingMatchGroups[bldg.bldgId] = (bldg.matchGroups ?? []).map((mg) => mg.matchGroup).join(";");
                this.buildingMessages[bldg.bldgId] = bldg.buildingMsg;
            });
            this.studentBuildings = (studentBuildings || []).map((b: MatchStudentHousing) => {
                return {
                    itemId: b.bldgId,
                    itemName: b.building.bldgName,
                    itemDesc: b.building.bldgCode,
                    sortOrder: b.sortOrder,
                };
            });
            this.buildingSelector = true;
            this.cd.detectChanges();
        });
    };

    move = (direction: number, bldg: S25ItemI) => {
        let idx = this.buildingModel.selectedItems.indexOf(bldg);
        let newIdx = idx + direction;
        if (newIdx < 0 || newIdx >= this.buildingModel.selectedItems.length) return;
        this.buildingModel.selectedItems.splice(idx, 1, this.buildingModel.selectedItems[newIdx]);
        this.buildingModel.selectedItems.splice(newIdx, 1, bldg);
        this.cd.detectChanges();
    };

    setBuildings = () => {
        return StudentHousingService.setStudentBuildings(
            this.buildingSeason.seasonId,
            this.buildingModel.selectedItems.map((b, i) => {
                let bldgId = S25Util.parseInt(b.itemId);
                return {
                    seasonId: this.buildingSeason.seasonId,
                    bldgId: bldgId,
                    sortOrder: i + 1,
                    buildingMsg: this.buildingMessages[bldgId] || "",
                    matchGroups: (this.buildingMatchGroups[bldgId] || "")
                        .split(";")
                        .filter((mg) => mg && mg.trim())
                        .map((mg) => {
                            return {
                                bldgId: bldgId,
                                seasonId: this.buildingSeason.seasonId,
                                matchGroup: mg.trim(),
                            };
                        }),
                };
            }),
        );
    };
}
