import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild, ViewEncapsulation } from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { MatchProfile, Question, StudentHousingService } from "../student.housing.service";
import { Table } from "../../s25-table/Table";
import { GenericTableButtonComponent } from "../../s25-table/generics/generic.table.button.component";
import { jSith } from "../../../util/jquery-replacement";
import Cell = Table.Cell;
import { S25TableComponent } from "../../s25-table/s25.table.component";
import { Rules } from "../../s25-rule-tree/s25.rule.const";
import SourceItem = Rules.SourceItem;
import { AttachmentService } from "../../../services/attachment.service";
import { ProfileComponent } from "./profile.component";
import { S25Util } from "../../../util/s25-util";
import Column = Table.Column;
import { Season } from "../seasons/seasons.service";
import { S25ItemGenericComponent } from "../../s25-item/item-object/s25.item.generic.component";
import { AlertService } from "../../../services/alert.service";
import { GenericTableRenderHTMLComponent } from "../../s25-table/generics/generic.table.render.html.component";

@TypeManagerDecorator("s25-ng-admin-profile-list")
@Component({
    selector: "s25-ng-admin-profile-list",
    template: `
        <s25-ng-season-dropdown
            class="ngBlock c-margin-bottom--single"
            [(selectedSeason)]="season"
            (selectedSeasonChange)="onSeasonChange($event)"
        ></s25-ng-season-dropdown>

        @if (seasonSelected) {
            @if (segments?.length) {
                <select class="ngInlineBlock c-margin-bottom--single" [(ngModel)]="selectedSegments" multiple>
                    <option *ngFor="let segment of segments" [value]="segment">{{ segment }}</option>
                </select>
            }

            <s25-ng-checkbox class="ngBlock c-margin-bottom--single" [parentCd]="cd" [(modelValue)]="excludeMatched"
                >Exclude Matched</s25-ng-checkbox
            >

            <div class="c-margin-bottom--single">
                <label
                    >First Name
                    <input class="c-input" [(ngModel)]="firstName" />
                </label>

                <label class="c-margin-left--half"
                    >Last Name
                    <input class="c-input" [(ngModel)]="lastName" />
                </label>
            </div>

            <s25-ng-dropdown-search-criteria
                [(chosen)]="selectedQuestion"
                [type]="'matchQuestions'"
                [customFilterValue]="$any(season.seasonId)"
            ></s25-ng-dropdown-search-criteria>

            @if (selectedQuestion?.itemTypeId) {
                <label class="c-margin-top--single">
                    Answer:
                    <select
                        [(ngModel)]="selectedAnswer"
                        (ngModelChange)="addQuestionAnswer(selectedQuestion, selectedAnswer)"
                    >
                        @for (child of selectedQuestion.children; track child) {
                            <option [value]="child.itemId">
                                {{ child.itemName }}
                            </option>
                        }
                    </select>
                </label>
            }

            @for (questionAnswer of questionAnswers; track questionAnswer) {
                <div class="c-margin-top--single">
                    <div>{{ questionAnswer.question }}: {{ questionAnswer.answer }}</div>
                </div>
            }

            <s25-ng-button [buttonClass]="'ngBlock c-margin-top--half'" [type]="'primary'" [onClick]="refreshTable"
                >Search</s25-ng-button
            >
            <s25-ng-button [buttonClass]="'ngBlock c-margin-top--half'" [type]="'outline'" [onClick]="clearSearch"
                >Clear</s25-ng-button
            >

            <s25-ng-table
                [hidden]="!!viewedProfile"
                [caption]="'Admin Profile Search'"
                [unlimitedWidth]="true"
                [columnSortable]="true"
                [dataSource]="dataSource"
                [hasTotalRowCount]="true"
                [hasRefresh]="true"
                [showHeaderWhenNoData]="true"
            ></s25-ng-table>

            @if (viewedProfile) {
                <div class="c-margin-top--single viewedProfile">
                    <div class="c-margin-bottom--single">
                        {{ viewedProfile?.contact?.firstName }} {{ viewedProfile?.contact?.familyName }}
                    </div>
                    @if (viewedProfile.friend) {
                        @if (viewedProfile.friend.friendMsg) {
                            <div class="c-margin-bottom--single">
                                Friend Message: {{ viewedProfile.friend.friendMsg }}
                            </div>
                        }
                    }
                    @if (viewedProfile.imageUri) {
                        <div>
                            <span>Profile Picture: </span>
                            <img [src]="viewedProfile.imageUri" alt="Profile Picture" />
                        </div>
                    }
                    @for (answer of viewedProfile.answers; track answer) {
                        <div class="c-margin-bottom--single">
                            <div>{{ answer.question.question }}</div>
                            <div>{{ answer.longAnswer || answer.answer }}</div>
                        </div>
                    }
                    <a href="javascript:void(0)" (click)="backToSearchResults()">Back to Search Results</a>
                </div>
            }
        }
    `,
    styles: `
        .viewedProfile {
            padding-top: 1.25rem;
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            border: 1px solid black;
            border-radius: 10px;
            margin-left: auto;
            margin-right: auto;
            max-width: 50%;
        }

        s25-ng-drag-handle {
            margin: auto 0;
        }

        .sortContainer ul {
            padding-inline-start: 0;
        }

        .sortContainer ul li {
            list-style-type: none;
            padding: 0.5em 0;
            border-bottom: 1px solid #ddd;
            display: flex;
        }

        ::ng-deep s25-ng-admin-profile-list s25-item-space .s25-item-label {
            display: none !important;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminProfileListComponent {
    @ViewChild(S25TableComponent) table: S25TableComponent;

    season: Season;
    segments: string[] = [];
    selectedSegments: string[] = [];
    seasonSelected = false;
    dataSource: Table.Unpaginated;
    viewedProfile: MatchProfile;
    init = false;
    excludeMatched = false;
    selectedQuestion: SourceItem;
    selectedAnswer: string;
    questionAnswers: { questionId: number; question: string; answer: string }[] = [];
    firstName: string;
    lastName: string;
    questions: Question[] = [];

    constructor(public cd: ChangeDetectorRef) {}

    addQuestionAnswer = (question: SourceItem, answer: string) => {
        this.questionAnswers.push({ questionId: question.itemId, question: question.itemName, answer });
        this.selectedQuestion = null;
        this.selectedAnswer = null;
        this.cd.detectChanges();
    };

    getRows = async () => {
        let rows: Table.Row[] = [];
        let profiles = await StudentHousingService.getProfiles(
            this.season.seasonId,
            null,
            this.firstName,
            this.lastName,
            this.questionAnswers,
            this.selectedSegments,
            this.excludeMatched,
            true,
        );

        if (profiles?.length) {
            jSith.forEach(profiles, (idx, profile: MatchProfile) => {
                const cells: Table.Row["cells"] = {};
                for (let i = 0; i < this.dataSource.columns.length; i++) {
                    let column = this.dataSource.columns[i];
                    let cell: Cell = {};
                    if (column.id === "firstName") {
                        cell.text = profile?.contact?.firstName ?? "";
                    } else if (column.id === "familyName") {
                        cell.text = profile?.contact?.familyName ?? "";
                    } else if (column.id === "event") {
                        if (profile.eventId) {
                            cell.sortProp = "itemName";
                            cell.component = S25ItemGenericComponent;
                            cell.inputs = {
                                itemTypeId: 1,
                                itemId: profile.eventId,
                            };
                        } else {
                            cell.text = "";
                        }
                    } else if (column.id === "room") {
                        if (profile.roomId) {
                            cell.sortProp = "itemName";
                            cell.component = S25ItemGenericComponent;
                            cell.inputs = {
                                itemTypeId: 4,
                                itemId: profile.roomId,
                            };
                        } else {
                            cell.text = "";
                        }
                    } else if (column.id === "link") {
                        let url =
                            window.location.origin +
                            window.location.pathname +
                            "#!/match/profile/" +
                            profile.inviteHash;
                        cell.component = GenericTableRenderHTMLComponent;
                        cell.inputs = {
                            isHtml: true,
                            text: `<a target="_blank" href="${url}">Profile</a>`,
                        };
                    } else if (S25Util.isNumeric(column.id)) {
                        let answer = profile.answers.find((a) => a.questionId === S25Util.parseInt(column.id));
                        cell.text = answer?.longAnswer || answer?.answer || "";
                    }
                    cells[column.id] = cell;
                }
                rows.push({
                    id: profile.contId,
                    name: profile.contId + "",
                    cells: cells,
                    data: profile,
                });
            });
        }
        this.cd.detectChanges();
        return {
            rows: rows,
            totalRows: rows.length,
        };
    };

    sync = async (row: Table.Row, instance?: GenericTableButtonComponent) => {
        let profile = row.data as MatchProfile;
        const [resp, error] = await S25Util.Maybe(StudentHousingService.syncProfile(profile.contId));
        error && S25Util.showError(error);
        await this.refreshTable();
    };

    reset = async (row: Table.Row, instance?: GenericTableButtonComponent) => {
        const confirmed = await AlertService.confirm(
            "This will delete the associated event and remove this profile's, as well as any roommate profiles', room and roommate assignments. Proceed?",
        );
        if (confirmed) {
            let profile = row.data as MatchProfile;
            const [resp, error] = await S25Util.Maybe(StudentHousingService.resetProfile(profile.contId));
            error && S25Util.showError(error);
            await this.refreshTable();
        }
    };

    view = async (row: Table.Row, instance?: GenericTableButtonComponent) => {
        this.viewedProfile = row.data as MatchProfile;
        this.viewedProfile.imageUri = await AttachmentService.getFileTextIfExists(
            3,
            this.viewedProfile.contId,
            ProfileComponent.profilePictureFileName,
            "inline",
        );
        this.cd.detectChanges();
    };

    backToSearchResults = () => {
        this.viewedProfile = null;
        this.cd.detectChanges();
    };

    refreshTable = async () => {
        this.cd.detectChanges();
        await this.table.refresh();
        this.cd.detectChanges();
    };

    clearSearch = () => {
        this.selectedQuestion = null;
        this.selectedAnswer = null;
        this.questionAnswers = [];
        return this.refreshTable();
    };

    onSeasonChange = async (season: Season) => {
        this.seasonSelected = false;
        this.cd.detectChanges();

        const segments = StudentHousingService.getSegments(season.seasonId);
        const questions = StudentHousingService.getQuestions(this.season.seasonId);
        this.segments = await segments;
        this.questions = await questions;

        let questionCols: Column[] = this.questions
            .filter((q) => q.questionType === "SELECT" && q.questionLevel === "SEARCH_COLUMN")
            .map((q) => {
                return {
                    id: q.questionId + "",
                    header: q.question,
                    sortable: true,
                };
            });

        let columns: Column[] = [
            {
                id: "firstName",
                header: "First Name",
                sortable: true,
            },
            {
                id: "familyName",
                header: "Last Name",
                sortable: true,
            },
            {
                id: "link",
                header: "Profile",
                sortable: false,
            },
            {
                id: "event",
                header: "Event",
                sortable: true,
            },
            {
                id: "room",
                header: "Room",
                sortable: true,
            },
        ];

        columns = columns.concat(questionCols);
        columns = columns.concat([
            GenericTableButtonComponent.Column("Sync", this.sync),
            GenericTableButtonComponent.Column("Reset", this.reset),
            GenericTableButtonComponent.Column("View", this.view),
        ]);

        this.dataSource = {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: columns,
        };

        this.seasonSelected = true;
        this.cd.detectChanges();
    };
}
