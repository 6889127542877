import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { MatchProfile, StudentHousingService } from "../student.housing.service";
import { Season } from "../seasons/seasons.service";
import { S25Util } from "../../../util/s25-util";
import { ReportService } from "../../../services/report.service";

@TypeManagerDecorator("s25-ng-season-process")
@Component({
    selector: "s25-ng-season-process",
    template: `
        <div>
            <s25-ng-season-dropdown
                class="ngBlock c-margin-bottom--single"
                [(selectedSeason)]="season"
                (selectedSeasonChange)="onSeasonSelect(season)"
            ></s25-ng-season-dropdown>

            @if (segments?.length) {
                <select class="ngInlineBlock c-margin-top--single" [(ngModel)]="selectedSegments" multiple>
                    <option *ngFor="let segment of segments" [value]="segment">{{ segment }}</option>
                </select>
            }

            <label class="ngBlock c-margin-top--single">
                Allow Excess Capacity
                <input type="checkbox" [(ngModel)]="allowExcessCapacity" />
            </label>

            <label class="ngBlock c-margin-top--single">
                Allow Single Occupancy
                <input type="checkbox" [(ngModel)]="allowSingleOccupancy" />
            </label>

            <label class="ngBlock c-margin-top--single">
                Honor Desired Capacity
                <input type="checkbox" [(ngModel)]="honorDesiredCap" />
            </label>

            @if (totalProcessed !== undefined) {
                <div class="c-margin-top--single">
                    <p>Total Processed: {{ totalProcessed }}</p>
                    <p>Total New: {{ totalNew }}</p>
                    <p class="c-margin-top--half">
                        To view these events, run an events search for Event Type: Student Housing and start/end dates
                        corresponding to this season (or use the custom attribute "Match Season" with this season's
                        identifying code).
                    </p>
                    <div class="c-margin-top--single">
                        <h3>Newly Assigned</h3>
                        @for (student of createdAssignments; track student.contId) {
                            <div class="c-margin-top--half c-margin-left--single">
                                <span>{{ student.contact.firstName }} {{ student.contact.familyName }}:&nbsp;</span>
                                <s25-item-event
                                    class="s25-object-event"
                                    [itemId]="student.eventId"
                                    [itemName]="student.eventName"
                                ></s25-item-event>
                            </div>
                        }
                        @if (!createdAssignments.length) {
                            <p class="c-margin-left--single noResults">None</p>
                        }
                    </div>
                    <div class="c-margin-top--single">
                        <h3>Already Assigned</h3>
                        @for (student of existingAssignments; track student.contId) {
                            <div class="c-margin-top--half c-margin-left--single">
                                <span>{{ student.contact.firstName }} {{ student.contact.familyName }}:&nbsp;</span>
                                <s25-item-event
                                    class="s25-object-event"
                                    [itemId]="student.eventId"
                                    [itemName]="student.eventName"
                                ></s25-item-event>
                            </div>
                        }
                        @if (!existingAssignments.length) {
                            <p class="c-margin-left--single noResults">None</p>
                        }
                    </div>
                    <div class="c-margin-top--single">
                        <h3>Not Assigned</h3>
                        @for (student of notAssignedStudents; track student.contId) {
                            <div class="c-margin-top--half c-margin-left--single">
                                <span>{{ student.contact.firstName }} {{ student.contact.familyName }}</span>
                            </div>
                        }
                        @if (!notAssignedStudents.length) {
                            <p class="c-margin-left--single noResults">None</p>
                        }
                    </div>
                    <s25-ng-button
                        [buttonClass]="'ngInlineBlock c-margin-top--single'"
                        [type]="'outline'"
                        [onClick]="exportResults"
                        >Export Results</s25-ng-button
                    >
                </div>
            }
            @if (allowProcess) {
                <s25-ng-button [buttonClass]="'c-margin-top--single'" [type]="'primary'" [onClick]="processSeason"
                    >Process</s25-ng-button
                >
            }
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeasonProcessComponent implements OnInit {
    season: Season;
    segments: string[] = [];
    selectedSegments: string[] = [];
    totalProcessed: number;
    totalNew: number;
    allowProcess = false;
    allowExcessCapacity = false;
    allowSingleOccupancy = false;
    honorDesiredCap = true;
    createdAssignments: MatchProfile[] = [];
    existingAssignments: MatchProfile[] = [];
    notAssignedStudents: MatchProfile[] = [];

    constructor(private cd: ChangeDetectorRef) {}

    processSeason = async () => {
        if (!this.season?.seasonId) {
            alert("Please choose a season to process");
            return;
        }
        this.createdAssignments = [];
        this.existingAssignments = [];
        this.notAssignedStudents = [];
        const respMsg = await StudentHousingService.processSeason(
            this.season.seasonId,
            this.selectedSegments,
            this.allowExcessCapacity,
            this.allowSingleOccupancy,
            this.honorDesiredCap,
        );
        if (respMsg.messageCode !== "success") {
            alert(respMsg.message || "An error occurred");
            return;
        }
        this.totalProcessed = respMsg.upsertData?.length || 0;
        this.totalNew = respMsg.upsertData?.filter((o) => S25Util.toBool(o.wasRowInserted)).length || 0;
        respMsg.upsertData?.forEach((o) => {
            let profile = o as unknown as MatchProfile;
            if (profile.wasRowInserted) {
                this.createdAssignments.push(profile);
            } else if (profile.eventId) {
                this.existingAssignments.push(profile);
            } else {
                this.notAssignedStudents.push(profile);
            }
        });
        this.cd.detectChanges();
    };

    onSeasonSelect = async (season: Season) => {
        this.segments = await StudentHousingService.getSegments(season.seasonId);
        this.allowProcess = true;
        this.cd.detectChanges();
    };

    exportResults = () => {
        return new Promise((resolve) => {
            let tsv = "Status\tName\tEvent\n";
            this.createdAssignments.forEach((student) => {
                tsv += `Assigned\t${student.contact.firstName} ${student.contact.familyName}\t${student.eventId}\n`;
            });
            this.existingAssignments.forEach((student) => {
                tsv += `AlreadyAssigned\t${student.contact.firstName} ${student.contact.familyName}\t${student.eventId}\n`;
            });
            this.notAssignedStudents.forEach((student) => {
                tsv += `NotAssigned\t${student.contact.firstName} ${student.contact.familyName}\t\n`;
            });
            ReportService.download(`student-housing-assignments-${Date.now()}.tsv`, tsv, "text/csv");
            resolve(true);
        });
    };

    ngOnInit() {}
}
