import { TypeManagerDecorator } from "../../../main/type.map.service";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { MatchProfile, StudentHousingService } from "../student.housing.service";
import { Table } from "../../s25-table/Table";
import { Season } from "../seasons/seasons.service";
import { S25Util } from "../../../util/s25-util";
import { S25TableComponent } from "../../s25-table/s25.table.component";

@TypeManagerDecorator("s25-ng-student-housing-email")
@Component({
    selector: "s25-ng-student-housing-email",
    template: `
        @if (init) {
            <s25-ng-season-dropdown
                [(selectedSeason)]="emailSeason"
                (selectedSeasonChange)="onEmailSeasonChange($event)"
            ></s25-ng-season-dropdown>

            @if (emailSeason) {
                <label class="ngBlock c-margin-top--single"
                    >Email From
                    <input type="email" class="c-input" [(ngModel)]="emailFrom" />
                </label>

                <label class="ngBlock c-margin-top--single"
                    >Email Subject
                    <input type="text" class="c-input" [(ngModel)]="emailSubject" />
                </label>

                <s25-ng-rich-text-editor
                    class="ngInlineBlock c-margin-top--single"
                    [rteLabel]="'Email Content'"
                    [(modelValue)]="emailContent"
                ></s25-ng-rich-text-editor>

                @if (origEmailProfiles.length) {
                    <div>
                        <h3>Email Profiles</h3>

                        <label class="ngBlock c-margin-top--single">
                            Filter Uninvited:
                            <input type="checkbox" [(ngModel)]="filterUninvited" (ngModelChange)="filterProfiles()" />
                        </label>

                        <label class="ngBlock c-margin-top--single">
                            Filter Segments:
                            <select multiple [(ngModel)]="selectedSegments" (ngModelChange)="filterProfiles()">
                                <option *ngFor="let segment of segments" [value]="segment">
                                    {{ segment }}
                                </option>
                            </select>
                        </label>

                        <label class="ngBlock c-margin-top--single">
                            Filter by Name and Email:
                            <input type="text" [(ngModel)]="filterStr" (ngModelChange)="onNameAndEmailFilterChange()" />
                        </label>

                        <label class="ngBlock c-margin-top--single">
                            Filter by Selected:
                            <input type="checkbox" [(ngModel)]="filterSelected" (ngModelChange)="filterProfiles()" />
                        </label>

                        <div class="c-margin-top--single">
                            <button class="aw-button aw-button--outline" (click)="selectAllProfiles()">
                                Select All
                            </button>

                            <button
                                class="aw-button aw-button--outline c-margin-left--single"
                                (click)="selectNoProfiles()"
                            >
                                Select None
                            </button>

                            <button class="aw-button aw-button--outline c-margin-left--single" (click)="clearFilters()">
                                Clear Filters
                            </button>
                        </div>

                        <s25-ng-table
                            [caption]="'Profile Emails'"
                            [unlimitedWidth]="true"
                            [columnSortable]="true"
                            [hasTotalRowCount]="false"
                            [hasRefresh]="false"
                            [hasSelect]="true"
                            [hasSelectAll]="false"
                            [dataSource]="dataSource"
                            [selected]="selectedEmailProfiles"
                            (selectedChange)="onSelectedChange($event)"
                        ></s25-ng-table>

                        <div class="c-margin-top--single">
                            <s25-ng-button [type]="'outline'" [onClick]="sendInviteEmails">Invite</s25-ng-button>
                            <s25-ng-button
                                [buttonClass]="'c-margin-left--single'"
                                [type]="'primary'"
                                [onClick]="sendAllEmails"
                                >Email</s25-ng-button
                            >
                        </div>
                    </div>
                }
            }
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentHousingEmailComponent implements OnInit {
    constructor(private cd: ChangeDetectorRef) {}

    @ViewChild(S25TableComponent) table: S25TableComponent;

    emailSeason: Season;
    emailFrom: string = "";
    emailSubject: string = "Student Housing";
    emailContent: string =
        "Hello {{firstName}} {{lastName}},<br/><br/>Click the following link to view your Student Housing Profile: {{inviteUrl}}";
    filterUninvited: boolean = false;
    filterSelected: boolean = false;
    filterStr: string = "";
    origEmailProfiles: MatchProfile[] = [];
    filterEmailProfiles: MatchProfile[] = [];
    segments: string[] = [];
    selectedSegments: string[] = [];
    selectedEmailProfiles: Set<Table.Row["id"]> = new Set();
    dataSource: Table.Unpaginated;
    init = false;

    onEmailSeasonChange = (season: Season) => {
        StudentHousingService.getAllProfiles(season.seasonId).then((profiles) => {
            this.origEmailProfiles = profiles;
            this.filterEmailProfiles = [].concat(this.origEmailProfiles);
            for (let profile of this.origEmailProfiles) {
                if (this.segments.indexOf(profile.segment) === -1) {
                    this.segments.push(profile.segment);
                }
            }
            this.segments.sort();
            this.cd.detectChanges();
        });
    };

    onNameAndEmailFilterChange = S25Util.debounce(() => {
        this.filterProfiles();
    }, 500);

    filterProfiles = () => {
        this.filterEmailProfiles = this.origEmailProfiles.filter((profile) => {
            let segmentFilter =
                this.selectedSegments.length === 0 || this.selectedSegments.indexOf(profile.segment) !== -1;
            let uninvitedFilter = !this.filterUninvited || !profile.lastInviteDate;
            let nameAndEmailFilter =
                !this.filterStr ||
                profile.contact.firstName.toLowerCase().includes(this.filterStr.toLowerCase()) ||
                profile.contact.familyName.toLowerCase().includes(this.filterStr.toLowerCase()) ||
                profile.contact.email.toLowerCase().includes(this.filterStr.toLowerCase());
            let filterSelectedFilter = !this.filterSelected || profile.selected;
            return segmentFilter && uninvitedFilter && nameAndEmailFilter && filterSelectedFilter;
        });
        this.table.refresh(true, true).then(() => {
            this.selectedEmailProfiles = new Set(this.origEmailProfiles.filter((p) => p.selected).map((p) => p.contId));
            this.cd.detectChanges();
        });
    };

    getRows = async (query: Table.PaginatedQuery): Promise<Table.DataSourceResponse> => {
        return {
            rows: this.filterEmailProfiles.map((profile) => {
                return {
                    id: profile.contId,
                    name: profile.contId + "",
                    cells: {
                        first: { text: profile.contact.firstName },
                        last: { text: profile.contact.familyName },
                        email: { text: profile.contact.email },
                        segment: { text: profile.segment },
                    },
                };
            }),
        };
    };

    onSelectedChange = (selected: Set<Table.Row["id"]>) => {
        this.origEmailProfiles.forEach((profile) => (profile.selected = selected.has(profile.contId)));
        this.selectedEmailProfiles = new Set(this.origEmailProfiles.filter((p) => p.selected).map((p) => p.contId));
        this.cd.detectChanges();
    };

    clearFilters = () => {
        this.filterStr = "";
        this.filterUninvited = false;
        this.selectedSegments = [];
        this.filterSelected = false;
        this.filterProfiles();
    };

    selectAllProfiles = async () => {
        this.filterEmailProfiles.forEach((p) => (p.selected = true));
        this.selectedEmailProfiles = new Set(this.origEmailProfiles.filter((p) => p.selected).map((p) => p.contId));
        await this.table.refresh(true, true);
        this.cd.detectChanges();
    };

    selectNoProfiles = async () => {
        this.filterEmailProfiles.forEach((p) => (p.selected = false));
        this.selectedEmailProfiles = new Set();
        await this.table.refresh(true, true);
        this.cd.detectChanges();
    };

    sendInviteEmails = async () => {
        this.cd.detectChanges();
        let validationError = this.validateEmail();
        if (validationError) {
            alert(validationError);
            return;
        }
        return StudentHousingService.invite(
            this.emailSeason.seasonId,
            this.emailFrom,
            this.emailSubject,
            this.emailContent,
            this.origEmailProfiles.filter((p) => p.selected),
        );
    };

    sendAllEmails = async () => {
        this.cd.detectChanges();
        let validationError = this.validateEmail();
        if (validationError) {
            alert(validationError);
            return;
        }
        return StudentHousingService.email(
            this.emailSeason.seasonId,
            this.emailFrom,
            this.emailSubject,
            this.emailContent,
            this.origEmailProfiles.filter((p) => p.selected),
        );
    };

    validateEmail = () => {
        if (!this.emailFrom) {
            return "Please enter an email address in the 'Email From' field.";
        }
        if (!this.emailSubject) {
            return "Please enter a subject in the 'Email Subject' field.";
        }
        if (!this.emailContent) {
            return "Please enter content in the 'Email Content' field.";
        }
        if (this.origEmailProfiles.filter((p) => p.selected).length === 0) {
            return "Please select at least one profile.";
        }
        return null;
    };

    ngOnInit() {
        this.dataSource = {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: [
                {
                    id: "first",
                    header: "First",
                    sortable: true,
                },
                {
                    id: "last",
                    header: "Last",
                    sortable: true,
                },
                {
                    id: "email",
                    header: "Email",
                    sortable: true,
                },
                {
                    id: "segment",
                    header: "Segment",
                    sortable: true,
                },
            ],
        };
        this.init = true;
        this.cd.detectChanges();
    }
}
